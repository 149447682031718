/** login_sense.js - adapts page header with login/logout structures
 **
 ** Author: Ernest Vogelsinger <evo@seso.at>
 ** Version: 1.2.0
 ** Date: 07.11.2022
 **
 ** v.1.1.0   14.11.2022  adds retrieving remote user data
 **                       modifies logged-in detection
 ** v.1.2.0   18.11.2022  using pKey to store login/logout status
 **                       to minimize backend requests
 ** v.1.2.1   25.11.2022  fixed edge case where a changed login would not be detected
 **                       if only the content of pKey changes (diff. KUMS)
 **                       situation can only occur if a logout/relogin happens without this
 **                       script being executed after the logout (i.e. inconsistent6 portal situation)
 **                       effect would be that the wrong name was displayed to the user (from the cache)
 ** v.1.2.2   09.12.2022  switched from fetch() to XMLHttpRequest:
 **                       in A1 stack, the X-Requested-With request header was supressed
 ** v.1.2.3   14.12.2022  More lenient status and name detection in getDATA(), details see function comments
 **/

 (function(window, document) {
  // default config values, see documentation for details
  const defaults = {
    // DATA is the overridable default
    DATA: {
      loggedin: false,
      menurequested: false,
      closeonclick: true,  // when menu is open:
                            // true - a click on the user symbol will close it
                            // false - only a outside click (or close button) will close the menu
      // time of day borders - numbers are hours, first match will trigger
      // logic is "hours >= from and hours < to", so any span wrapping midnight needs two entries
      tod: [
        {from: 17, to: 24, key: "evening"},
        {from:  0, to:  3, key: "evening"},
        {from:  4, to:  9, key: "morning"},
        {                  key: "day"}
      ],
      // "user" object contains specific user data for greeting
      user: {
        first: "",           // optional
        last:  ""            // optional
      },
      greeting: {
        // title to be used if user has no data
        unknown: {
          morning: "Guten Morgen!",
          day: "Guten Tag!",
          evening: "Guten Abend!"
        },
        // title to be used when user name is known
        known: {
          morning: "%user.first%",
          day: "%user.first%",
          evening: "%user.first%"
        }
      },
      symboltext: {
        loggedin: {
          title: "Ihr persönliches Benutzermenü",
        },
        anon: {
          title: "Klick zum Login",
        }
      },
      menu: [
        // entries for popup menu
        {
            text: "Produktübersicht",
            href: "https://www.a1.net/mein-a1",
            target: "_self"    // optional, default is "_self"
          },
          {
            text: "Rechnungen",
            href: "https://www.a1.net/mein-a1/rechnungen"
          },
          {
            text: "Kundenkonto",
            href: "https://asmp.a1.net/msslogin?serviceId=ADM"
          },
          {
            text: "Meine Angebote",
            href: "https://asmp.a1.net/msslogin?serviceId=OFFER"
          }
            // ...
      ],
      buttons: [
        // same as menu, supports multiple buttons just in case
        {
          text: "Logout",
          href: "http://asmp.a1.net/asmp/logout?reloginDisableAutologin=https%3A//www.a1.net/",
          target: "_self",    // optional, default is "_self"
          islogout: "true",   // set true if click should clear the pKey cookie
        },
      ],
      popup: {
        html: {
          // the unprocessed HTML for the popup element
          popup:'  <div class="inner">\n' +
                '    <div class="navigation-container">\n' +
                '      <div class="login-welcome">\n' +
                '        <strong>$utils.makeTitle?Hallo!$</strong>\n' +
                '      </div>\n' +
                        '$utils.createMenu$' +
                        '$utils.createButtons$' +
                '    </div>\n' +
                ' </div>',
          // HTML for menu wrapper
          menuwrapper: '<div class="user-nav">\n' +
                '  <nav><ul>' +
                '$utils.createMenuentries$\n' +
                '  </ul></nav>\n' +
                '</div>',
          // HTML for a single menu entry
          menu: '\n      <li><a href="%href%" title="%text%" target="%target?_self%">%text%</a></li>',
          // HTML for button wrapper
          buttonswrapper: '<div class="user-login">$utils.createButton$\n</div>',
          // HTML for single button element
          button: '\n    <a href="%href%" data-islogout="%islogout%" class="button arrowright button-secondary button-mobile-small" title="%text%">%text%' +
                  '<span class="click-response"><span class="click-response__effect" style="--mouse-x:115.938px; --mouse-y:8px;"></span></span>' +
                  '</a>',
        },
      }
    },
    // not overridable config data
    PROTECTED: {
      login: {
        cookie: 'pKey',
        values: {
          anon: "null",       // pKey value for detected non-loggedin state
          loggedin: "none",   // pKey value for detected empty-loggedin state (user w/o KUMS)
        },
        hreflogin: "https://www.a1.net/mein-a1",
        hrefnone: "javascript:void(0)",
      },
      overlay: {
        // menu wrapper
        menuwrapper: 'nav',
        // these selectors must be available, we don't check availability in document and/or popup html.
        // if missing, this solution can't work.
        selectors: {
          link: '#js-log-symbol',         // unique selector for symbol link
          user: "#js-log-symbol",         // unique selector for user symbol
          parent: "#js-log-parent",       // unique selector for container
          container: "#js-log-container", // unique selector for popup container
          close: "#js-log-menu-close",    // unique selector of menu close button
          bglayer: ".bg-layer-open-nav",  // background layer
        },
        classes: {
          menuopen: "is-open",
          // bglayerOpen: "bg-layer-open-nav", // background layer open class
          bglayerIsOpen: "is-open" // background layer open class
        },
        // these classes will be initialized upon init
        initclasses: {
          anon: [
            {
              selector: '#js-log-parent',
              noclass: 'is-open',
            },
            {
              selector: '#js-log-symbol',
              noclass: 'is-loggedin',
            },
            {
              selector: '#js-log-menu',
              remove: true,
            }
          ],
          loggedin: [
            {
              selector: '#js-log-parent',
              noclass: 'is-open',
            },
            {
              selector: '#js-log-symbol',
              class: 'is-loggedin',
            },
          ],
        },
      },
      source: {
        href: 'https://www.a1.net/tnps-support/?action=asmpheaders',       // URL should return a JSON object which contains one or more values for DATA
        fetchoptions: {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        },
        xhr: {
          method: "GET",
          headers: {
            "X-Requested-With": "XMLHttpRequest",
          },
        },
        timeout: 2000,  // fuse timeout
        storage: {
          version: 1.1,
          key: 'logsense.data',
          validity: 30, // validity timeout in minutes
        },
      }
    }
  }

  // utility methods/functions
  const utils = {
    // Similar to Object.assign, but performs a deep assign
    // without losing properties when passing incomplete objects, e.g. as update-only
    deepassign: function(...objs) {
      const isobj = function(obj) {
        return 'object' === typeof obj && !Array.isArray(obj)
      }
      const result = objs.shift()
      if (!isobj(result)) return result
      while (objs.length) {
        let obj = objs.shift()
        if (!isobj(obj)) return obj
        for (let prop in obj) {
          if (isobj(result[prop])) {
            result[prop] = this.deepassign(result[prop], obj[prop])
          }
          else {
            result[prop] = this.deepassign(obj[prop])
          }
        }
      }
      return result
    },
    // return the top level domain
    tld: function() {
        if ('localhost' === document.location.hostname) return document.location.hostname
        const ar = document.location.hostname.split('.')
        return '.' + ar.slice(ar.length-2).join('.')
    },
    // return the value of one cookie
    cookie: function(key) {
      if (!this._cookies) {
        this._cookies = {}
        document.cookie.split(/;\s*/).forEach((ckl) => {
          const i = ckl.indexOf('=')
          this._cookies[ckl.substring(0, i).trim()] = ckl.substring(i+1).trim()
        })
      }
      return this._cookies[key.trim()]
    },
    setcookie: function(key, value) {
      document.cookie = `${key}=${value}; expires=0; path=/; domain=${this.tld()}`
      delete this._cookies
      this.cookie(key)
    },
    // get the login cookie (pKey)
    getlogincookie: function() {
      // in the beginning, DATA is not yet set
      return this.cookie(this.getIE("login.cookie", defaults.PROTECTED))
    },
    haslogincookie: function() {
      return !!(this.getlogincookie())
    },
    islogincookie: function() {
      const cv = this.getlogincookie()
      return (cv && this.getIE("login.values.anon") !== cv)
    },
    setlogincookie: function(loggedin) {
      if (loggedin) {
        const cv = this.getlogincookie()
        if (!cv || this.getIE("login.values.anon") == cv) {
          this.setcookie(this.getIE("login.cookie"), this.getIE("login.values." +(loggedin ? "loggedin" : "anon")))
        }
      }
      else {
        this.setcookie(this.getIE("login.cookie"), this.getIE("login.values.anon"))
      }
    },
    setlogoutcookie: function() {
      utils.setcookie(utils.getIE("login.cookie"), '')
    },
    // return time of day as "morning", "day", or "evening"
    tod: function() {
        const hrs = new Date().getHours()
        const artod = this.getIE("tod")
        let result
        if (artod && Array.isArray(artod)) {
          artod.some((tod)=> {
            if (Object.hasOwn(tod, "from") && Object.hasOwn(tod, "to")) {
              if (hrs >= tod.from && hrs < tod.to) result = tod.key
            }
            else result = tod.key
            return !!result
          })
        }
        return result ? result : "day"
    },
    // retrieve an indexed element from a JSON object
    getIE: function(index, object) {
        if (null == object) object = DATA
        if ("string" === typeof index) index = index.split(".")
        if (!Array.isArray(index) || !index.length) return object
        if ("object" !== typeof object && index.length) return null

        object = object[index.shift()]
        return undefined === object ? null : this.getIE(index, object)
    },
    // checks if user data is known
    isKnownUser: function() {
        return !!(this.getIE("user.first") || this.getIE("user.last"))
    },
    // replace placeholders with value
    // %indexed.string?default%: replaced by value available at DATA[indexed.string], with optional default
    processPlaceholders: function(input, object) {
        const regex = /(?<pre>[^\$%]*?)(?<delim>[\$%])(?<data>[^\2]*?)\2(?<post>.*)/s
        const regres = regex.exec(input);
        if (!regres) return input;
        let result = regres.groups.pre;
        const keydef = regres.groups.data.split('?')
        keydef.push('')     // make sure there are at least two entries
        let data;
        switch(regres.groups.delim) {
            case '%':
                data = this.getIE(keydef[0], object)
                break;
            case '$':
                const fn = this.getIE(keydef[0], object)
                if ('function' === typeof fn) data = fn.call(this);
                break;
        }
        data = this.processPlaceholders(data, object)
        result += data ? data : keydef[1]
        return result + this.processPlaceholders(regres.groups.post, object)
    },
    // creates the title string
    makeTitle: function() {
        const title = this.getIE("greeting." + (this.isKnownUser() ? "known" : "unknown") + "." + this.tod())
        return this.processPlaceholders(title)
    },
    // menu creator
    hasMenu: function() {
        const menu = this.getIE("menu")
        return !!(Array.isArray(menu) && menu.length)
    },
    createMenu: function() {
        return this.processPlaceholders(this.getIE("popup.html.menuwrapper"))
    },
    createMenuentries: function() {
        const entries = this.getIE("menu")
        const html = this.getIE("popup.html.menu")
        let result = '';
        if (Array.isArray(entries)) {
            entries.forEach((entry) => {
                result += this.processPlaceholders(html, entry)
            })
        }
        return result
    },
    // buttons creator
    hasButtons: function() {
        const buttons = this.getIE("buttons")
        return !!(Array.isArray(buttons) && buttons.length)
    },
    createButtons: function() {
        return this.processPlaceholders(this.getIE("popup.html.buttonswrapper"))
    },
    createButton: function() {
        const entries = this.getIE("buttons")
        const html = this.getIE("popup.html.button")
        let result = '';
        if (Array.isArray(entries)) {
            entries.forEach((entry) => {
                result += this.processPlaceholders(html, entry)
            })
        }
        return result
    },
    createPopup: function()  {
        return this.processPlaceholders(this.getIE("popup.html.popup"))
    }
  }

  const storage = {
    // storage uses the "defaults" structure as it is used before DATA gets constructed
    store: function() {
      const key   = defaults.PROTECTED.source.storage.key
      const valid = defaults.PROTECTED.source.storage.validity
      const stored = {
        data: Object.assign({}, DATA),
        cookie: utils.getlogincookie(),
        version: defaults.PROTECTED.source.storage.version,
        valid: new Date().getTime() + valid * 60000,  // convert minutes to microseconds
      }
      localStorage.setItem(key, JSON.stringify(stored))
    },
    retrieve: function() {
      const key = defaults.PROTECTED.source.storage.key
      try {
        const stored = JSON.parse(localStorage.getItem(key))
        if (stored
            && 'object' === typeof stored
            && !Array.isArray(stored)
            && stored.version == defaults.PROTECTED.source.storage.version
            && stored.valid >= new Date().getTime()
            && stored.cookie === utils.getlogincookie()) {
          return Object.assign(stored.data)
        }
        else {
          localStorage.removeItem(key)
          return null
        }
      }
      catch(e) {
        localStorage.removeItem(key)
        return null
      }
    },
    remove: function() {
      const key = defaults.PROTECTED.source.storage.key
      localStorage.removeItem(key)
    }
  }

  // DOM manipulation and runtime event callbacks
  const proc = {
    // hides or shows an element
    show: function(elem, isshow) {
//        elem.style.display = (isshow ? 'block' : 'none')
    },
    // creates the popup element, returns true on success
    createPopup: function() {
      const elPopup = document.createElement("div")
      const parent  = document.querySelector(utils.getIE("overlay.selectors.container"))
      if (!parent || !elPopup) return false
      elPopup.innerHTML = DATA.html
      // instrument optional close box
      const elclose = elPopup.querySelector(utils.getIE("overlay.selectors.close"))
      if (elclose) elclose.addEventListener('click', proc.showMenu)
      // move all child nodes into container
      parent.childNodes.forEach(node => parent.removeChild(node))
      while (elPopup.childNodes.length > 0) parent.appendChild(elPopup.childNodes[0])
      // instrument logout links
      parent.querySelectorAll('[data-islogout="true"]').forEach(node => {
        node.addEventListener('click', utils.setlogoutcookie)
      })
      proc.show(parent, false)
      return true
    },
    // sets or unsets class attributes
    initclasses: function(ardesc, root) {
      if (!root || !root.querySelector) root = document
      if (!Array.isArray(ardesc)) ardesc = [ardesc]
      ardesc.forEach((desc) => {
        let element = root.querySelector(desc.selector)
        if (element) {
          if (desc.remove) {
            element.remove()
          }
          else {
            if (desc.class && !Array.isArray(desc.class)) desc.class = desc.class.split(/\s*,\s*/)
            if (desc.noclass && !Array.isArray(desc.noclass)) desc.noclass = desc.noclass.split(/\s*,\s*/)
            if (desc.class)   desc.class.forEach  ((cls) => element.classList.add(cls))
            if (desc.noclass) desc.noclass.forEach((cls) => element.classList.remove(cls))
          }
        }
      })
    },
    // sets the link of the symbol element and instruments the menu click
    setlink: function(isloggedin) {
      const elem = document.querySelector(utils.getIE("overlay.selectors.link"))
      if (elem) {
        let href, title
        if (!isloggedin) {
          href = utils.getIE("login.hreflogin")
          title = utils.getIE('symboltext.anon.title')
        }
        else {
          href = utils.getIE("login.hrefnone")
          title = utils.getIE('symboltext.loggedin.title')
        }
        elem.setAttribute('href', href)
        elem.setAttribute('title', title)
        proc.setuserlink(isloggedin ? 1 : 0, elem)
      }
    },
    setuserlink: function(activate, elem) {
      if (!elem) elem = document.querySelector(utils.getIE("overlay.selectors.link"))
      if (elem) {
        elem.removeEventListener('click', proc.openMenu)
        elem.removeEventListener('click', proc.scheduleMenu)
        elem.removeEventListener('click', proc.gotoLink)
        elem.removeEventListener('click', proc.gotoLogin)
      }
      // if (activate >= 0) elem.addEventListener('click', 2 === activate ? proc.scheduleMenu : (!!activate ? proc.openMenu : proc.gotoLink))
      if (activate >= 0) elem.addEventListener('click', 2 === activate ? proc.scheduleMenu : (!!activate ? proc.openMenu : proc.gotoLogin))
    },
    scheduleMenu: function() {
      DATA.menurequested = true
    },
    openMenu: function(event) {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      proc.setuserlink(-1)
      proc.showMenu(true)
    },
    // action methods
    showMenu: function(isshow) {
      const menu   = document.querySelector(utils.getIE("overlay.selectors.container"))
      const parent = document.querySelector(utils.getIE("overlay.selectors.parent"))
      if (menu && parent) {
        if (isshow) {
          proc.show(menu, true)
          parent.classList.add(utils.getIE("overlay.classes.menuopen"))
          document.addEventListener('click', proc.handleOutsideClick)
        }
        else {
          document.removeEventListener('click', proc.handleOutsideClick)
          parent.classList.remove(utils.getIE("overlay.classes.menuopen"))
          setTimeout(() => {
            proc.show(menu, false)
          }, 1000)
        }
      }
    },
    gotoLink: function() {
      // avoid the bglayer when clicking for login
      const bglayer = document.querySelector(utils.getIE('overlay.selectors.bglayer'))
      if (bglayer) bglayer.classList.remove(utils.getIE('overlay.classes.bglayerIsOpen'))
      window.location.href = utils.getIE("login.hreflogin")
    },
    gotoLogin: function(event) {
      event.preventDefault();
      event.stopPropagation();
      // avoid the bglayer when clicking for login
      const bglayer = document.querySelector(utils.getIE('overlay.selectors.bglayer'))
      if (bglayer) bglayer.classList.remove(utils.getIE('overlay.classes.bglayerIsOpen'))
      // remove empty dropdown container, because there is no content and is should not get opened
      const menu = document.querySelector(utils.getIE("overlay.selectors.container"));
      if (menu) {
        menu.remove();
      }

      const loginLink = document.querySelector(utils.getIE("overlay.selectors.link"))
      const hasTweetyLogin = loginLink.getAttribute('data-tweetylogin') ? loginLink.getAttribute('data-tweetylogin') : 'false';
      const tweetyOverlay = document.getElementById('a1-tweetylogin-overlay') ? document.getElementById('a1-tweetylogin-overlay') : undefined;
      // if there is an overlay and the data attribute to use is set "true" and tweetylogin init is "complete"
      if (typeof tweetyOverlay !== 'undefined' && hasTweetyLogin === 'true' && typeof window.tweetyLoginDeferred !== 'undefined' && window.tweetyLoginDeferred === 'complete') {
        // open tweetylogin overlay
        a1TweetyloginOverlay.openOverlay();
      } else {
        // go to asmp login page
        window.location.href = utils.getIE("login.hreflogin");
      }
    },
    handleOutsideClick: function(event) {
      const elm = document.querySelector(utils.getIE("overlay.selectors.container"))
      const elu = document.querySelector(utils.getIE("overlay.selectors.user"))
      const closeonclick = utils.getIE("closeonclick")
      if (!((elm && elm.contains(event.target)) || (!closeonclick && elu && elu.contains(event.target)))) {
        proc.setuserlink(1)
        proc.showMenu(false)
      }
    },
    /**
     *
     * @returns getDATA will, if the backend returns data, use the following attributes to determine login status and user name:
     *
     * loggedin:  0 < login_level
     *            !!login_type && 'NONE' !== login_type
     * firstname: user_firstname_encoded
     *            user_firstname
     *            portal_displayname.split(/\s+/).shift()
     *            user_first_name_ci_encoded
     * lastname:  user_lastname_encoded
     *            user_lastname
     *            portal_displayname.split(/\s+/).pop()
     *            user_last_name_ci_encoded
     *
     * getDATA will gobble up all errors and return "not logged in" in any case except success
     */
    getDATA: async function() {
      const keys = {
        first: {
          op: "shift",
          keys: ["user_firstname_encoded", "user_first_name_ci_encoded"] // "user_firstname", "portal_displayname",
        },
        last: {
          op: "pop",
          keys: ["user_lastname_encoded", "user_last_name_ci_encoded"] // "user_lastname", "portal_displayname",
        }
      }
      const extractUser = (json) => {
        const user = {}
        for (const key in keys) {
          const ctl = keys[key]
          ctl.keys.some(attr => {
            user[key] = attr in json.result && !!json.result[attr] ? decodeURI(json.result[attr].split(/\s+/)[ctl.op]().trim()) : ""
            return !!user[key]
          })
        }
        return user
      }
      return new Promise((resolve) => {
        const result = {
          loggedin: false,
        }
        const source = Object.assign({timeout: 2000}, DATA.source)
        const xhr = new XMLHttpRequest()
        const fuse = window.setTimeout(() => {
          xhr.abort()
          resolve(result)
          }, source.timeout)
        xhr.open(source.xhr.method, source.href)
        for (hdr in source.xhr.headers) {
          xhr.setRequestHeader(hdr, source.xhr.headers[hdr])
        }

        xhr.onreadystatechange = function() {
          // check for DONE only, ignore the rest
          if (4 === this.readyState) {
            window.clearTimeout(fuse)
            if (200 === this.status) {
              let  data = this.responseText
              if (['application/javascript', 'application/json', 'text/javascript', 'text/json'].includes(this.getResponseHeader('Content-Type'))) {
                try {
                  const json = JSON.parse(data)
                  if (json && 'OK' === json.status) {
                    // check login level, only show as "loggedin" if it's 10, 30 or 40, if user is autologgedin (20) or loggedout -> request login
                    result.loggedin = (!!Number(json.result.login_level) && (json.result.login_level === '10' || json.result.login_level === '30' || json.result.login_level === '40')) && (!!json.result.login_type !== 'NONE')
                    // result.loggedin = !!Number(json.result.login_level) || (!!json.result.login_type && 'NONE' !== json.result.login_type)
                    if (result.loggedin) {
                      result.user = extractUser(json)
                    }
                  }
                }
                catch(e) {
                  // no change to result
                }
              }
              // not logged in if not 200
            }
            resolve(result)
          }
        }
        xhr.send();

      })
    },
    /**
     * This was the original getDATA method, using fetch()
     * Somehow the X-Requested-With header is supressed (in A1 only) so the call doesn't succeed
     *
    _getDATA: async function() {
      let   result = {loggedin: DATA.loggedin}  // will be true for a KUMS (pKey) user!
      const source = Object.assign({timeout: 2000, fetchoptions: {}}, DATA.source)
      if (source.href) {
        const controller = new AbortController()
        const fuse = window.setTimeout(()=>controller.abort(), source.timeout)
        try {
          const response = await fetch(source.href, {...source.fetchoptions, signal: controller.signal})
          window.clearTimeout(fuse)
          if (response) {
            const json = await response.json()
            if (json && 'OK' === json.status && !!Number(json.result.login_level)) {
              result.loggedin = true
              result.user = {
                  first: json.result.user_firstname_encoded,
                  last:  json.result.user_lastname_encoded
              }
            }
            else {
              result.loggedin = false
            }
          }
        }
        catch(ex) {}
      }
      return result
    }
     **/
  }

  // this will be the module-global DATA
  let DATA

  // async initializer
  // loggedin detection:
  //  1 - no pKey cookie, or pKey value (null vs. other) and DATA.loggedin don't match
  //    1a - get data from storage, if DATA.loggedin -> store
  //    1b - update login cookie if necessary
  //  2 - DATA.loggedin, generated HTML missing? generate it, -> store
  //  3 - DATA.loggedin? create DOM entries for user menu, set classes and links
  //  4 - else clear storage, and instrument login link
  const init = async function() {
    const setDATA = (...data) => {
      DATA = utils.deepassign({}, defaults.DATA, ...data, defaults.PROTECTED, {utils: utils})
    }
    const setLoggedout = () => {
        // remove storage, and setup classes
        storage.remove()
        proc.initclasses(utils.getIE("overlay.initclasses.anon"))
        proc.setlink(false)
    }
    setDATA(storage.retrieve())

    // next steps are possibly async and waiting for the backend,
    // so if the user clicks the login button before the API returns
    // this click is recorded, and the menu will open as soon as available
    proc.setuserlink(2)   // 0: no click handler | 2: schedule menu open | default: open menu on click

    if (!utils.haslogincookie() || (utils.islogincookie() !== DATA.loggedin)) {
      // empty pKey value: either not logged in, or "empty" user
      setDATA(await proc.getDATA())
      utils.setlogincookie(DATA.loggedin)
      if (DATA.loggedin) storage.store()
    }

    // at this point DATA and login state has been set up correctly
    if (DATA.loggedin && !DATA.html) {
      DATA.html = utils.createPopup()
      storage.store()
    }
    if (DATA.loggedin) {
      proc.createPopup()
      proc.initclasses(utils.getIE("overlay.initclasses.loggedin"))
      proc.setlink(true)
      if (DATA.menurequested) {
        // DATA.menurequested = false
        // proc.openMenu()
        setTimeout(() => {
          const elem = document.querySelector(utils.getIE('overlay.selectors.link'))
          if (elem) elem.click()
        }, 500)
      }
      else {
        proc.setuserlink(1)
      }
    }
    else {
      utils.setlogincookie(false)
      setLoggedout()
      // user clicked during init - send to button target URL
      if (DATA.menurequested) {
        // proc.gotoLink()
        proc.openTweetyOverlay()
      }
    }
  }

  // wait until everything is available
  document.addEventListener('DOMContentLoaded', init)
})(window, document)
