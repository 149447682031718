/** form-init.js - Initialize Virtual Select on selected items
 **
 ** Author: Berthold Kremser <bkr@seso.at>
 ** Version: 1.0.0
 ** Date: 30.11.2022
 **
 **/

function initFreshDropdowns() {
    // Check if this function has been called before
    if (document.querySelector('#virtual-select-css') === null) {
        const styled_selects = document.querySelectorAll('.js-styled-select');
        if (styled_selects.length > 0) {
            // Load CSS file
            var cssId = 'virtual-select-css';
            if (!document.getElementById(cssId)) {
                var head  = document.getElementsByTagName('head')[0];
                var link  = document.createElement('link');
                link.id   = cssId;
                link.rel  = 'stylesheet';
                link.type = 'text/css';
                link.href = '/o/a1-fresh-liferay-theme/css/extensions/virtual-select.min.css';
                link.media = 'all';
                head.appendChild(link);
            }

            // Load JS file and init via promise
            import("https://cdn22.a1.net/o/a1-fresh-liferay-theme/js/extensions/virtual-select.min.js")
                .then((module) => {
                    module.loadVirtualSelect();

                    VirtualSelect.init({
                        ele: '.js-styled-select',
                        showDropboxAsPopup: 'false',
                        hideClearButton: true,
                        placeholder: ' ',
                        noOptionsText: 'Keine Optionen verfügbar',
                        noSearchResultsText: 'Keine Ergebnisse gefunden',
                        selectAllText: 'Alle auswählen',
                        searchPlaceholderText: 'Suchen',
                        optionsSelectedText: 'Optionen ausgewählt',
                        optionSelectedText: 'Option ausgewählt',
                        allOptionsSelectedText: 'Alle ausgewählt',
                        clearButtonText: 'Entfernen',
                        moreText: 'Mehr ...'
                    });
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    }
}

document.addEventListener('DOMContentLoaded', function() {
    initFreshDropdowns();
});
